.base {
  @apply h-13 px-4 rounded-md placeholder-brown-light text-brown border transition-shadow;

  &:disabled {
    @apply text-brown-light;

    cursor: not-allowed;
  }

  &:focus {
    @apply ring-2 ring-gray-darker;

    outline: none;
  }

  &.light {
    @apply shadow-sm border-white bg-white;
  }

  &.dark {
    @apply border-gray-lighter bg-gray-light;
  }

  &.error {
    @apply border-error;
  }
}
