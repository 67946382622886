.option {
  @apply px-4 py-2 text-sm text-brown-light rounded-md;

  display: flex;
  align-items: center;
  position: relative;
  cursor: default;
  background: none;
  white-space: nowrap;

  &.focused {
    @apply bg-gray text-brown;
  }

  &.selected::before {
    @apply transform -translate-y-1/2 left-2 w-1 h-1 rounded-full bg-red;

    position: absolute;
    content: '';
    top: 50%;
  }
}

.control:hover {
  cursor: pointer;
}

.indicatorSeparator {
  display: block;

  @apply mr-2;
}

.base {
  @apply w-full;

  [class$='-singleValue'] {
    @apply p-0;
  }

  [class$='-indicatorSeparator'] {
    display: none;
  }

  [class$='-placeholder'] {
    @apply text-brown-light;

    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 2rem);
    white-space: nowrap;
  }

  [class$='-menu'] {
    @apply p-2 shadow border border-gray-dark;
  }

  .control {
    @apply flex items-center px-2;

    min-height: 2.5rem;

    &.focused {
      @apply ring-2 ring-gray-darker;
    }
  }

  .valueContainer {
    @apply text-brown;
  }
}

.default {
  .control {
    @apply shadow-md;
  }
}

.transparent {
  [class$='-singleValue'] {
    @screen lg {
      @apply font-semibold;
    }
  }

  [class$='-placeholder'] {
    @apply text-base;

    @screen lg {
      @apply font-semibold;
    }
  }

  [class$='-menu'] {
    @apply px-2 py-4 rounded-md shadow;

    width: 100%;

    @screen lg {
      @apply transform -translate-x-6 px-2 py-4 mt-4 rounded-3xl;

      min-width: 14rem;
    }
  }

  .control {
    @apply bg-transparent px-0 border-none;

    min-height: 3.5rem;
    box-shadow: none;

    @screen lg {
      min-height: 1rem;
    }

    &.focused {
      box-shadow: none;
    }
  }

  .valueContainer {
    @apply p-0;

    @screen lg {
      @apply font-semibold text-base;
    }
  }
}

.small {
  .control {
    @apply h-10 text-sm;
  }
}

.big {
  .control {
    @apply h-13;

    @screen lg {
      min-height: auto;
    }
  }
}

.dark {
  [class$='-placeholder'] {
    @apply text-brown-light;
  }

  .control {
    @apply bg-gray-light rounded-md border border-gray-light;

    &:hover {
      @apply border-gray-light;
    }

    &.error {
      @apply border-error;
    }
  }
}
