.switchButtonWrapper {
  @apply w-28 h-8 rounded-md text-red-dark;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @screen md {
    @apply w-8 h-8;
  }

  &:focus {
    @apply ring-2 ring-gray-darker ring-offset-0;

    outline: none;
  }

  &.active {
    @apply bg-gray-dark;
  }
}

.label {
  @apply text-brown-light text-sm ml-2;

  @screen md {
    @apply sr-only;
  }

  &.active {
    @apply text-brown;
  }
}
