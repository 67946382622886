.base {
  @apply rounded transition-all leading-none;

  display: flex;
  align-items: center;

  &:focus {
    @apply ring-2 ring-gray-darker rounded-md;

    outline: none;
  }

  &:hover {
    @apply opacity-hover;
  }

  &:disabled {
    @apply opacity-disabled;

    cursor: not-allowed;
  }
}
