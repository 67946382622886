.input {
  @apply w-5 h-5 appearance-none rounded-full transition-shadow;

  cursor: pointer;
  position: relative;

  &::before {
    @apply w-2.5 h-2.5 bg-red rounded-full transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity;

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::after {
    @apply w-5 h-5 border-2 border-brown-light rounded-full transform -translate-x-1/2 -translate-y-1/2 transition-colors;

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &:checked {
    &::before {
      @apply opacity-100;
    }

    &::after {
      @apply border-red;
    }
  }

  &:focus {
    @apply ring-2 ring-gray-darker ring-offset-2;

    outline: none;
  }
}
