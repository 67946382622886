.base {
  @apply font-medium rounded-md transition-all;

  &:focus {
    @apply ring-2 ring-gray-darker;

    outline: none;
  }

  &.primary {
    @apply text-red;
  }

  &.secondary {
    @apply text-brown-light;
  }
}
