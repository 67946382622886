@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* work-sans-regular - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
      url('/fonts/work-sans-v9-latin-regular.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/work-sans-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* work-sans-italic - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''),
      url('/fonts/work-sans-v9-latin-italic.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/work-sans-v9-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* work-sans-500 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('/fonts/work-sans-v9-latin-500.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/work-sans-v9-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* work-sans-600 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''), url('/fonts/work-sans-v9-latin-600.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/work-sans-v9-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* work-sans-700 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('/fonts/work-sans-v9-latin-700.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/work-sans-v9-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  html {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-display: swap;
    color: theme('colors.brown.DEFAULT');

    body {
      background: theme('colors.gray.light');

      &.hideOverflow {
        overflow: hidden;
      }

      &.variantWhite {
        background: theme('colors.white');
      }
    }

    h1 {
      font-size: 2.25rem;
      line-height: 2.5rem;
      font-weight: 700;
    }

    h2 {
      font-size: 1.75rem;
      line-height: 2.15rem;
      font-weight: 500;

      &.redDot {
        @apply pl-6;

        position: relative;

        &::before {
          @apply transform -translate-y-1/2 w-2.5 h-2.5 rounded-full bg-red;

          content: '';
          position: absolute;
          top: 50%;
          left: 0;
        }
      }
    }

    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;

      &.redDot {
        @apply pl-6;

        position: relative;

        &::before {
          @apply transform -translate-y-1/2 w-3 h-3 rounded-full bg-red;

          content: '';
          position: absolute;
          top: 50%;
          left: 0;
        }
      }
    }

    h4 {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;
    }

    h5 {
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;
    }

    h6 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  a {
    @apply rounded-md transition-shadow transition-colors;

    &:focus {
      @apply ring-2 ring-gray-darker;

      outline: none;
    }
  }
}

.custom-carousel {
  @screen xl {
    margin: 0 -1rem;
  }
}

.custom-carousel .carousel {
  .slide {
    @apply px-0;

    background: transparent;

    @screen xl {
      @apply px-4;
    }
  }

  .slide img {
    width: auto;
  }
}

.Toastify__toast-body {
  @apply px-5 py-2 shadow-sm;
}

.Toastify__progress-bar {
  background: rgba(0, 0, 0, 0.07);
}

.Toastify__toast--info {
  @apply text-info bg-info-light;

  .Toastify__close-button > svg {
    @apply text-info;
  }
}

.Toastify__toast--error {
  @apply text-error bg-error-light;

  .Toastify__close-button > svg {
    @apply text-error;
  }
}

.Toastify__toast--warning {
  @apply text-warning bg-warning-light;

  .Toastify__close-button > svg {
    @apply text-warning;
  }
}

.Toastify__toast--success {
  @apply text-success bg-success-light;

  .Toastify__close-button > svg {
    @apply text-success;
  }
}
