.base {
  @apply px-8 rounded-md border transition-all;

  align-items: center;
  justify-content: center;

  &:focus {
    @apply ring-2 ring-gray-darker ring-offset-2;

    outline: none;
  }

  &:hover {
    @apply opacity-hover;
  }

  &:disabled {
    @apply opacity-disabled;

    cursor: not-allowed;
  }

  &.primary {
    @apply border-red text-red;

    font-weight: 600;

    &.filled {
      @apply bg-red text-white;

      &:focus {
        @apply ring-gray-darker;
      }
    }
  }

  &.secondary {
    @apply border-gray text-brown;

    &:hover {
      @apply border-red text-red opacity-100;
    }

    &:focus {
      @apply ring-2 ring-gray-darker;
    }

    &.filled {
      @apply border-gray bg-gray text-brown;

      &:hover {
        @apply border-gray-dark bg-gray-dark;

        &:disabled {
          @apply border-gray bg-gray opacity-disabled;
        }
      }
    }
  }

  &.link {
    @apply ring-gray-darker;
  }

  &.small {
    @apply h-10 text-sm px-9;
  }

  &.medium {
    @apply h-12;
  }

  &.big {
    @apply h-13;
  }

  &.fluid {
    @apply h-full;
  }
}
