.checkmark {
  @apply w-5 h-5 mr-3 rounded-sm appearance-none transition-shadow;

  flex-shrink: 0;
  position: relative;
  cursor: pointer;

  &::before {
    @apply w-5 h-5 border-2 border-brown-light rounded-sm transform transition-colors;

    transform: translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::after {
    @apply w-3 h-1.5 top-1.5 left-1 opacity-0 border-l-2 border-b-2 border-white transform -rotate-45;

    content: '';
    display: inline-block;
    position: absolute;
  }

  &:focus {
    @apply ring-2 ring-gray-darker ring-offset-2;

    outline: none;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  input:checked ~ .checkmark {
    &::after {
      @apply opacity-100;
    }

    &::before {
      @apply border-red bg-red;
    }
  }
}
