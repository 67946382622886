.wrapper {
  @apply py-0.5 bg-gray rounded-sm;

  display: inline-flex;

  .button {
    @apply w-6 h-6 mx-0.5 bg-gray rounded-sm transition-all;

    &:focus {
      @apply ring-2 ring-gray-darker;

      outline: none;
    }

    &:hover {
      @apply bg-gray-dark;
    }
  }

  .input {
    @apply w-8 h-6 px-0.5 text-center leading-6 text-sm bg-white transition-all;

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      @apply bg-white;
    }
  }

  &.lg {
    @apply py-1 rounded-md;

    .button {
      @apply w-12 h-12 rounded-md;
    }

    .input {
      @apply w-14 h-12 text-lg;
    }
  }
}
