.base {
  @apply rounded-md transition-all;

  display: flex;
  align-items: center;
  font-weight: 500;

  &:focus {
    @apply ring-2 ring-gray-darker ring-offset-2;

    outline: none;
  }

  &:hover {
    @apply opacity-hover;
  }

  &:disabled {
    @apply opacity-disabled;

    cursor: not-allowed;
  }
}
